<template>
  <van-grid :column-num="4" style="margin:7px 0 0 0;">
    <van-grid-item>
      <p>
        <small>{{ (comment.favorableRating * 100).toFixed(1) }}%</small>
      </p>
      <p>
        <small>好评率</small>
      </p>
    </van-grid-item>
    <van-grid-item @click="onChange('GOOD')">
      <p>
        <small>{{ comment.good }}</small>
      </p>
      <p>
        <small>好评</small>
      </p>
    </van-grid-item>
    <van-grid-item @click="onChange('GENERAL')">
      <p>
        <small>{{ comment.general }}</small>
      </p>
      <p>
        <small>中评</small>
      </p>
    </van-grid-item>
    <van-grid-item @click="onChange('BAD')">
      <p>
        <small>{{ comment.bad }}</small>
      </p>
      <p>
        <small>差评</small>
      </p>
    </van-grid-item>
  </van-grid>
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
  >
    <template v-for="item in list" :key="item">
      <div style="background:#fff;padding:15px;margin:7px 0 0 0;line-height:1.6">
        <p>
          <small>评论类型: {{ item.commentGradeText }}</small>
        </p>
        <p>
          <small>评论内容: {{ item.commentContent }}</small>
        </p>
        <p>
          <small>订单编号: {{ item.orderNumber }}</small>
        </p>
        <!-- <p>
          <small>业务类型 {{ item.bizScopeText }}</small>
        </p> -->
        <p>
          <small>商品名称: {{ item.productName }}</small>
        </p>
        <p>
          <small>实收金额: &yen;{{ item.payAmount }}</small>
        </p>
        <p>
          <small>分账金额: &yen;{{ item.commissionAmount }}</small>
        </p>
        <p>
          <small>最终收益: &yen;{{ item.profitAmount }}</small>
        </p>
        <p v-if="item.member">
          <small>会员手机号: {{ item.buyer.user.phone }}</small>
        </p>
        <p>
          <small>下单时间: {{ item.createTime }}</small>
        </p>
        <van-button size="small" :to="{ name: 'OrderDetail', query: { orderId: item.orderId } }">查看订单</van-button>
      </div>
    </template>
  </van-list>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      page: 1,
      finished: false,
      loading: false,
      list: [],
      title: '',
      storeId: Cookies.get('storeId'),
      comment: {
        total: 0,
        good: 0,
        general: 0,
        bad: 0,
        favorableRate: 0
      },
      grade: undefined
    })
    const onChange = (v) => {
      state.grade = v
      reset()
    }
    const reset = () => {
      state.list = []
      state.page = 1
      state.finished = false
      state.loading = false
    }
    const getData = () => {
      post('/order.list', {
        pageNum: state.page,
        storeId: state.storeId,
        comment: true,
        commentGrade: state.grade
      }).then(res => {
        state.list = [...state.list, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
        state.title = '总共 ' + res.data.totalElements + ' 条记录'
      })
    }
    const sum = () => {
      post('/order.sumComment', {
        storeId: state.storeId,
        comment: true
      }).then(res => {
        state.comment = res.data
      })
    }
    const init = () => {
      sum()
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData,
      onChange
    }
  }
}
</script>

<style scoped>
body {
  background:#f9f9f9;
}
</style>
